/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { Link, graphql } from 'gatsby'
import { useFlexSearch } from 'react-use-flexsearch'

import Bio from '../components/bio'
import Layout from '../components/layout'
import Seo from '../components/seo'
import BlogPostCard from '../components/blogPostCard'
import BlogHeader from '../components/blogHeader'
import { BlogContainer, BlogResults } from '../pages/blog'
import styled from 'styled-components'

const Pagination = styled.nav`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 32px;
  padding: 24px 12px;
  border-top: 1px solid #e2e2e5;
  //border-bottom: 1px solid #e2e2e5;
  position: relative;
  transition: all 0.3s;

  &:hover {
    border-top: 1px solid transparent;
    //border-bottom: 1px solid transparent;
    transition: all 0.3s;

    &:before {
      background-color: #00adef;
      box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
      transition: all 0.3s;
    }
    a {
      color: #fbfbff;
      transition: all 0.3s;
    }
  }
  &:before {
    content: '';
    position: absolute;
    left: -50%;
    top: 0;
    z-index: 1;
    width: 200vw;
    height: 100%;
    background-color: transparent;
    box-shadow: none;
    transition: all 0.3s;
  }
  a {
    z-index: 2;
    padding-left: 0;
    &:hover {
      margin-left: -12px;
      transition: all 0.2s;

      &:last-of-type {
        margin-right: -12px;
        transition: all 0.2s;
      }
    }
    padding: 10px;
    font-family: 'Lexend Deca', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 120%;
    letter-spacing: 0.025em;
    color: #000000;
    transition: all 0.2s;
  }
  @media (max-width: 768px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 32px;
    padding: 24px 12px;
    margin-left: -10px;
    position: relative;
    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      z-index: 1;
      width: 100vw;
      height: 100%;
      background-color: #00adef;
      box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
    }
    a {
      padding: 4px;
      font-size: 15px;
    }
  }
`

const BlogIndex = ({
  data,
  pageContext: { nextPagePath, previousPagePath, pageNumber },
  location,
}) => {
  const posts = data.allWpPost.nodes
  const { index, store } = data.localSearchBlog

  const [query, setQuery] = useState(null)
  const results = useFlexSearch(query, index, store)

  if (!posts.length) {
    return (
      <Layout isHomePage>
        <Seo title='All posts' />
        <Bio />
        <p>
          Nenhum resultado encontrado para a busca:{' '}
          <span className='query'>{query}</span>
        </p>
      </Layout>
    )
  }

  return (
    <Layout location={location}>
      <Seo title='Blog' />

      <BlogHeader setQuery={setQuery} query={''} isPaged />
      <BlogContainer>
        {!query ? (
          <h2>
            Página <span className='query'>{pageNumber}</span>
          </h2>
        ) : (
          <h2>
            Resultados para: <span className='query'>{query}</span>
          </h2>
        )}

        <BlogResults query={query} posts={posts} results={results} />
      </BlogContainer>

      {!query ? (
        <Pagination>
          {previousPagePath && (
            <Link to={previousPagePath} className='pagingButton'>
              Página anterior
            </Link>
          )}
          {nextPagePath && (
            <Link to={nextPagePath} className='pagingButton next'>
              Próxima página
            </Link>
          )}
        </Pagination>
      ) : null}
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query WordPressPostArchive($offset: Int!, $postsPerPage: Int!) {
    allWpPost(
      sort: { fields: [date], order: DESC }
      limit: $postsPerPage
      skip: $offset
    ) {
      nodes {
        excerpt
        uri
        date(formatString: "DD \\d\\e MMMM, YYYY", locale: "pt-BR")
        title
        excerpt
        categories {
          nodes {
            name
          }
        }
        featuredImage {
          node {
            sourceUrl
          }
        }
      }
    }
    localSearchBlog {
      index
      store
    }
  }
`
